import React from "react"
import ImageMeta from "../../components/ImageMeta"
import NuvoImage from "../../components/NuvoImage"
import Button from "@components/Button/Button"

import ABOMS from "../../images/logo-ABOMS.svg"
import SocialReviews from "../../components/SocialReviews/SocialReviews"
import Stars from "../../components/SocialReviews/Stars"
import ContentColumn from "./ContentColumn"

function TopSection(props) {
  const { post } = props
  const badgeType = post.badge.badgeImage.includes("aboms") ? "ABOMS" : "AAOMS"

  if (props.post.hasImages) {
    return (
      <div className="columns is-desktop top-section doctor">
        <Button
          buttonText={props.language === "es" ? "ATRÁS" : "BACK"}
          goBack
          href={
            props.language === "es"
              ? "/es/nuestra-consulta-de-cirugia-oral-en-fresno-clovis-reedley-y-madera-ca/"
              : props.backUrl
          }
        />

        <div className="column is-1"></div>
        <div className="column doctor-image-wrapper is-11">
          <NuvoImage
            useAR
            cloudName="nuvolum"
            publicId={props.post.cutoutImage}
            width="auto"
          ></NuvoImage>
        </div>
        <div className="column is-1"></div>

        <ContentColumn
          post={props.post}
          heading={props.post.heading}
          topBlurb={props.post.topBlurb}
          badge={props.post.badge}
          badgeType={badgeType}
          language={props.language}
        />
        <div className="column"></div>
      </div>
    )
  }
  return (
    <>
      <div className="columns has-text-centered top-section doctor imageless is-vcentered">
        <Button
          buttonText={props.language === "es" ? "ATRÁS" : "BACK"}
          goBack
          href={
            props.language === "es"
              ? "/es/nuestra-consulta-de-cirugia-oral-en-fresno-clovis-reedley-y-madera-ca/"
              : props.backUrl
          }
        />

        <div className="column is-3"></div>
        <div className="column">
          <h1>{props.post.heading}</h1>
          <p className="large-doctor-text large">{props.post.topBlurb}</p>
          <div className="rating-review">
            <SocialReviews
              doctor={props.doctor}
              google
              isCentered
              language={props.language}
            />
            {props.post.badge && props.post.badge.hasBadge && (
              <img className="badge" src={ABOMS} />
            )}
          </div>
        </div>
        <div className="column is-3"></div>
      </div>
    </>
  )
}

export default TopSection
